<template>
  <div class="yt-content">
    <div class="filter">
      <div class="filter">
        <div>
          <span>人员筛选:</span>
          <el-dropdown placement="bottom-start">
            <span class="select"
              >{{ staffSelection.length > 0 ? `已选${staffSelection.length}人` : '全部' }}<i class="el-icon-arrow-down el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-input v-model="staffKeyword" class="yt-search search" placeholder="通过人员名称筛选">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNode"></i>
              </el-input>
              <div class="list-container">
                <el-tree
                  :data="staffData"
                  :props="staffProps"
                  node-key="userId"
                  ref="staff-tree"
                  :filter-node-method="filterNode"
                  show-checkbox
                  check-strictly
                >
                </el-tree>
              </div>
              <div class="footer">
                <el-button type="primary" @click="selectUser">确定</el-button>
                <el-button size="small" @click="resetSelection">
                  <div>
                    <YTIcon :href="'#icon-zhongzhi'"></YTIcon>
                    <p>重置</p>
                  </div>
                </el-button>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div>
          <span>课程筛选:</span>
          <el-dropdown @command="selectCourse" placement="bottom-start">
            <span class="select">{{ courseName }}<i class="el-icon-arrow-down el-icon--right"></i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(option, index) in courseOptions"
                :key="index"
                :command="option.courseId"
                :class="{
                  active: (option.courseId === 'all' && courseId === '') || option.courseId === courseId
                }"
              >
                {{ option.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div>
          <span>任务状态:</span>
          <el-dropdown @command="selectStatus" placement="bottom-start">
            <span class="select">{{ statusName }}<i class="el-icon-arrow-down el-icon--right"></i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(option, index) in taskStatus"
                :key="index"
                :command="option.status"
                :class="{
                  active: (option.status === 'all' && status === '') || option.status === status
                }"
              >
                {{ option.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 15px">人数: {{ departmentInfo.totalNum }}</span>
        <template v-if="departmentInfo.hasOwnProperty('leaderNames')">
          <el-tooltip effect="dark" :content="departmentInfo.leaderNames.toString()" placement="top-end">
            <div class="leader">负责人: {{ departmentInfo.leaderNames.toString() }}</div>
          </el-tooltip>
        </template>
        <el-button size="small" type="primary" style="width: 50px;margin-right: 15px" @click="exportReport">导出</el-button>
      </div>
    </div>
    <el-table
      ref="reportTable"
      :data="reportList"
      v-loading="reportLoading"
      element-loading-text="加载中"
      class="yt-table"
      height="calc(100% - 107px)"
    >
      <el-table-column type="expand" width="20px">
        <template slot-scope="props">
          <p>学习任务信息</p>
          <el-table :data="props.row.studyTasks" class="yt-table">
            <el-table-column label="课程信息" prop="courseName" show-overflow-tooltip />
            <el-table-column label="完成条件" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.passProgress + '%' }}</template>
            </el-table-column>
            <el-table-column label="学习进度" show-overflow-tooltip>
              <template slot-scope="scope">{{ Math.round(scope.row.courseProgress * 10000) / 100 + '%' }}</template></el-table-column
            >
            <el-table-column label="学习时长" prop="studyDurationString" show-overflow-tooltip />
            <el-table-column label="及格分数" prop="passExamScore" show-overflow-tooltip />
            <el-table-column label="测试分数" prop="examScores" show-overflow-tooltip />
            <el-table-column label="练习次数" prop="practiceCount" show-overflow-tooltip />
            <el-table-column label="练习题次" prop="questionCount" show-overflow-tooltip />
            <el-table-column label="正确率" show-overflow-tooltip>
              <template slot-scope="scope" v-if="scope.row.hasOwnProperty('accuracy')">{{
                Math.round(scope.row.accuracy * 10000) / 100 + '%'
              }}</template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="个人信息" show-overflow-tooltip>
        <el-table-column label="人员名称" prop="userName" show-overflow-tooltip />
        <el-table-column label="工号" prop="jobNum" label-class-name="has-border" show-overflow-tooltip />
      </el-table-column>
      <el-table-column label="总体学习任务信息" show-overflow-tooltip>
        <el-table-column label="总时长" prop="totalDurationString" show-overflow-tooltip />
        <el-table-column label="平均时长" prop="averageDurationString" show-overflow-tooltip />
      </el-table-column>
    </el-table>
    <Page
      class="yt-page"
      :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
      :total="total"
      :current="pageNum + 1"
      :page-size-opts="[10, 20, 40, 100]"
      :page-size="pageSize"
      @on-change="changePage"
      @on-page-size-change="changePageSize"
      show-elevator
      show-sizer
      show-total
    />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import statisticApi from '@api/statistic'
import departmentApi from '@/api/department'
export default {
  name: 'StaffStudyTaskTable',
  components: {
    YTIcon
  },
  data() {
    return {
      departmentInfo: {},
      departmentId: '',
      staffKeyword: '',
      staffData: [],
      staffProps: {
        label: 'userName'
      },
      staffSelection: [],
      userIds: [],
      courseId: '',
      statusName: '全部',
      status: '0',
      taskStatus: [
        { name: '全部', status: 'all' },
        { name: '已完成', status: '1' },
        { name: '未完成', status: '2' }
      ],
      courseName: '全部',
      courseOptions: [{ name: '全部', courseId: 'all' }],
      reportList: [],
      reportLoading: true,
      total: 0,
      pageNum: 0,
      pageSize: 10
    }
  },
  methods: {
    getDepartmentInfo(departmentId) {
      departmentApi.getDepartmentInfo(departmentId).then(res => {
        this.departmentInfo = res.res
        this.departmentId = departmentId
        this.getDepartmentCourse()
        this.searchReportList()
        this.getDepartmentUser()
      })
    },
    checkCourseInfo(payload) {
      this.departmentId = payload.departmentId
      this.courseId = payload.courseId
      this.status = payload.status
      this.statusName = '未完成'
      this.getDepartmentCourse()
      this.searchReportList()
      this.getDepartmentUser()
    },
    getDepartmentCourse() {
      this.courseOptions = [{ name: '全部', courseId: 'all' }]
      statisticApi.getDepartmentCourse(this.departmentId).then(res => {
        this.courseOptions = this.courseOptions.concat(res.res)
        this.$nextTick(() => {
          this.selectCourse(this.courseId, false)
        })
      })
    },
    getDepartmentUser() {
      statisticApi.getDepartmentStudyTaskUser(this.departmentId).then(res => {
        this.staffData = res.res
      })
    },
    searchReportList() {
      let payload = {
        departmentId: this.departmentId,
        courseId: this.courseId,
        studyUserIds: this.staffSelection,
        progressFlag: this.status
      }
      this.reportLoading = true
      statisticApi
        .getDepartmentStaffStudyInfo(payload, this.pageNum, this.pageSize)
        .then(res => {
          this.reportList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.reportLoading = false
        })
    },
    selectUser() {
      this.staffSelection = this.$refs['staff-tree'].getCheckedKeys()
      this.pageNum = 0
      this.searchReportList()
    },
    resetSelection() {
      this.$refs['staff-tree'].setCheckedNodes([])
    },
    selectCourse(courseId, flag) {
      if (courseId === 'all') {
        this.courseId = ''
        this.courseName = '全部'
      } else {
        this.courseId = courseId
        this.courseOptions.map(item => {
          if (item.courseId === courseId) {
            this.courseName = item.name
          }
        })
      }
      if (flag) {
        this.pageNum = 0
        this.searchReportList()
      }
    },
    selectStatus(status) {
      if (status === 'all') {
        this.statusName = '全部'
        this.status = '0'
      } else {
        this.status = status
        this.taskStatus.map(item => {
          if (item.status === status) {
            this.statusName = item.name
          }
        })
      }
      this.pageNum = 0
      this.searchReportList()
    },
    reSearchReportList(departmentId) {
      this.pageNum = 0
      this.courseId = ''
      this.staffSelection = []
      this.getDepartmentInfo(departmentId)
    },
    toFilterNode() {
      this.$refs['staff-tree'].filter(this.staffKeyword)
      this.$refs['staff-tree'].setCheckedNodes([])
    },
    filterNode(value, data) {
      //知识点树过滤
      if (!value) return true
      return data.userName.indexOf(value) !== -1
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchReportList()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchReportList()
    },
    exportReport() {
      let payload = {
        departmentId: this.departmentId,
        courseId: this.courseId,
        studyUserIds: this.staffSelection,
        progressFlag: this.status
      }
      statisticApi.exportDepartmentStaffStudyInfo(payload).then(res => {
        this.$downloadFlow(res, this.departmentInfo.departmentName, 'xls')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/filterDropdownMenu';
.filter {
  justify-content: space-between !important;
}
</style>
