<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>报表</el-breadcrumb-item>
        <el-breadcrumb-item>部门报表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="0">部门整体学习任务信息</el-menu-item>
        <el-menu-item index="1">部门整体考试信息</el-menu-item>
        <el-menu-item index="2">部门人员学习任务信息</el-menu-item>
        <el-menu-item index="3">部门人员考试信息</el-menu-item>
      </el-menu>
    </div>
    <div class="yt-container yt-container-flex" :class="{ 'is-pack-up': isPackUp }" style="height: calc(100% - 48px)">
      <div class="yt-content-tree">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>{{ organizationData.hasOwnProperty('name') ? organizationData.name : '' }}</p>
          </div>
          <div class="tree">
            <el-tree ref="department-tree" :data="departmentData" node-key="departmentId" :highlight-current="true" @node-click="handleNodeClick">
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="data.name" :disabled="data.name.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ data.name }}</div>
                </el-tooltip>
              </div>
            </el-tree>
          </div>
        </template>
      </div>
      <div class="yt-resize">
        ⋮
      </div>
      <component :is="tables[activeIndex]" ref="reportTable"></component>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import departmentApi from '@api/department'
import DepartmentStudyTaskTable from '@components/manage/report/tables/DepartmentStudyTaskTable'
import DepartmentExamTable from '@components/manage/report/tables/DepartmentExamTable'
import StaffStudyTaskTable from '@components/manage/report/tables/StaffStudyTaskTable'
import StaffExamTable from '@components/manage/report/tables/StaffExamTable'
export default {
  name: 'DepartmentReportList',
  components: {
    YTIcon,
    DepartmentStudyTaskTable,
    DepartmentExamTable,
    StaffStudyTaskTable,
    StaffExamTable
  },
  data() {
    return {
      activeIndex: '0',
      departmentId: '',
      isPackUp: false,
      organizationData: {}, //企业信息
      departmentData: [],
      tables: [DepartmentStudyTaskTable, DepartmentExamTable, StaffStudyTaskTable, StaffExamTable]
    }
  },
  mounted() {
    this.getDepartment()
    this.$dragDiv()
  },
  methods: {
    getDepartment() {
      departmentApi.getDepartmentsTree().then(res => {
        if (res.code === 0) {
          this.departmentData = res.res.children
          this.organizationData = res.res
          this.setCurrentKey(this.departmentData[0].departmentId)
        }
      })
    },
    setCurrentKey(departmentId) {
      if (departmentId === '') {
        return
      }
      this.departmentId = departmentId
      this.$nextTick(() => {
        this.$refs['department-tree'].setCurrentKey(departmentId ? departmentId : 0)
        this.$refs.reportTable.reSearchReportList(departmentId)
      })
    },
    selectMenu(index) {
      this.activeIndex = index
      this.setCurrentKey(this.departmentId)
    },
    checkCourseInfo(payload) {
      this.activeIndex = '2'
      this.$nextTick(() => {
        this.$refs.reportTable.checkCourseInfo(payload)
      })
    },
    handleNodeClick(data) {
      this.setCurrentKey(data.departmentId)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/dragDiv';
.yt-content {
  padding: 0 20px;
}
::v-deep .filter {
  .flexStyle(flex, flex-start, center);
  height: 52px;
  font-size: @medium;
  .el-dropdown {
    color: #000000;
    .select {
      margin: 0 15px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.yt-table {
  ::v-deep .el-table__header-wrapper {
    border-radius: 0;
  }
}
::v-deep .has-border {
  border-right: 1px solid #e2e2e2 !important;
  .cell {
    border: none !important;
  }
}
::v-deep .leader {
  margin-right: 10px;
  cursor: pointer;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
