<template>
  <div class="yt-content">
    <div class="filter">
      <div>
        <span>课程筛选:</span>
        <el-dropdown @command="selectCourse" placement="bottom-start">
          <span class="select">{{ courseName }}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(option, index) in courseOptions"
              :key="index"
              :command="option.courseId"
              :class="{
                active: option.courseId === courseId
              }"
            >
              {{ option.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="display: flex; align-items: center">
        <span style="margin-right: 15px">人数: {{ departmentInfo.totalNum }}</span>
        <template v-if="departmentInfo.hasOwnProperty('leaderNames')">
          <el-tooltip effect="dark" :content="departmentInfo.leaderNames.toString()" placement="top-end">
            <div class="leader">负责人: {{ departmentInfo.leaderNames.toString() }}</div>
          </el-tooltip>
        </template>
        <el-button size="small" type="primary" style="width: 50px;margin-right: 15px" @click="exportReport">导出</el-button>
      </div>
    </div>
    <el-table :data="reportList" v-loading="reportLoading" element-loading-text="加载中" class="yt-table" height="calc(100% - 107px)">
      <el-table-column label="课程信息" prop="courseName" show-overflow-tooltip />
      <el-table-column label="完成人数" prop="completeNum" show-overflow-tooltip />
      <el-table-column label="未完成人数" prop="incompleteNum" show-overflow-tooltip />
      <el-table-column label="完成率" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.completionRate + '%' }}</template>
      </el-table-column>
      <el-table-column label="操作" width="120px">
        <template slot-scope="scope">
          <span class="tool-button" @click="seeStaffInfo(scope.row.courseId)">查看未完成人员</span>
        </template>
      </el-table-column>
    </el-table>
    <Page
      class="yt-page"
      :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
      :total="total"
      :current="pageNum + 1"
      :page-size-opts="[10, 20, 40, 100]"
      :page-size="pageSize"
      @on-change="changePage"
      @on-page-size-change="changePageSize"
      show-elevator
      show-sizer
      show-total
    />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import statisticApi from '@api/statistic'
import departmentApi from '@api/department'
export default {
  name: 'DepartmentStudyTaskTable',
  components: {
    YTIcon
  },
  data() {
    return {
      departmentInfo: {},
      departmentId: '',
      courseId: '',
      courseName: '全部',
      courseOptions: [{ courseId: 'all', name: '全部' }],
      reportList: [],
      reportLoading: true,
      total: 0,
      pageNum: 0,
      pageSize: 10
    }
  },
  methods: {
    getDepartmentInfo(departmentId) {
      departmentApi.getDepartmentInfo(departmentId).then(res => {
        this.departmentInfo = res.res
        this.departmentId = departmentId
        this.getDepartmentCourse()
        this.searchReportList()
      })
    },
    getDepartmentCourse() {
      this.courseOptions = [{ courseId: 'all', name: '全部' }]
      statisticApi.getDepartmentCourse(this.departmentId).then(res => {
        this.courseOptions = this.courseOptions.concat(res.res)
      })
    },
    searchReportList() {
      let payload = {
        departmentId: this.departmentId,
        courseId: this.courseId
      }
      this.reportLoading = true
      statisticApi
        .getDepartmentStudyInfo(payload, this.pageNum, this.pageSize)
        .then(res => {
          this.reportList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.reportLoading = false
        })
    },
    reSearchReportList(departmentId) {
      this.pageNum = 0
      this.courseId = ''
      this.getDepartmentInfo(departmentId)
    },
    selectCourse(courseId) {
      if (courseId === 'all') {
        this.courseId = ''
        this.courseName = '全部'
      } else {
        this.courseId = courseId
        this.courseOptions.map(item => {
          if (item.courseId === courseId) {
            this.courseName = item.name
          }
        })
      }
      this.pageNum = 0
      this.searchReportList()
    },
    seeStaffInfo(courseId) {
      let payload = {
        courseId: courseId,
        status: '2',
        departmentId: this.departmentId
      }
      this.$parent.checkCourseInfo(payload)
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchReportList()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchReportList()
    },
    exportReport() {
      let payload = {
        departmentId: this.departmentId,
        courseId: this.courseId
      }
      statisticApi.exportDepartmentStudyInfo(payload).then(res => {
        this.$downloadFlow(res, this.departmentInfo.departmentName, 'xls')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/filterDropdownMenu';
.filter {
  justify-content: space-between !important;
}
</style>
